/*mobile view */
@media only screen and (max-width: 480px) {
    body {
      background-image: url("mobile-view.png");
    }
   .evvpLogo {
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .loginBox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80%;
    background-color: 'rgb(46,43,43,0.49)';
    border-radius: 16px;

   }
}

/* web view */
@media only screen
  and (min-width: 481px) {
    body {
        background-image: url('EVVP_TL_BG.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
}
.container{
    margin-top: 50px;
}
.heading{
    font-size: 3em;
}
.form{
    margin:auto;
    width: 80%;
}
.camera{
    margin-top:20px;
}
.recipes-container
{
    padding: 10px;
}
/* Custom CSS for date number colors */
.rdrCalendarWrapper {
   background-color: transparent !important;;
}
.rdrDateRangeWrapper{
    width: 240px;
    background-color: transparent;
}
.rdrDateRangeWrapper .rdrDays{
    background-color: transparent;
}
.rdrDateRangeWrapper .rdrMonth{
    background-color: 'transparent';
    width: 240px;
    padding: 0%;
}
.rdrDateRangeWrapper .rdrMonthAndYearWrapper{
    background-color: #AD282D;
    color: #fbfafa;
}

.rdrDateRangeWrapper .rdrMonthAndYearWrapper{
    background-color: #AD282D;
    color: #fbfafa;
}
.rdrCalendarWrapper {
    background-color: transparent;
}


.rdrDateRangeWrapper .rdrDayWeekend .rdrDayNumber span{
    color: #AD282D
}

.rdrDateRangeWrapper .rdrDayNumber {
    border-style: solid; /* Change text color of the nested <span> */
    border: '1px';
    border-color: #ABABAB;
    top: 0;
    bottom: 0;
    margin: 0%;
    padding: 0%;
  }

.rdrDateRangeWrapper .rdrDayNumber span {
    color: #999999; /* Change text color of the nested <span> */
    font-weight: bold;
  }

.rdrDateRangeWrapper .rdrWeekDays{
    background-color: #E6E6E6;
    ;
    top: 0;
    bottom: 0;
    margin: 0%;
    padding: 0%;
   }
.rdrDateRangeWrapper .rdrNextPrevButton {

color: #FFFFFF;
background-color: transparent;
}
.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Titillium Web", sans-serif;
    height: 100vh;
  }
  
  .btn {
    text-transform: uppercase;
  }
  
  .box-text {
    position: relative;
    font-size: 2em;
    color: rgba(66, 133, 244, 0.8);
  }
  
  .box-progress {
    position: relative;
    color: rgba(255, 23, 23, 0.3);
    font-size: 5em;
  }
  
  .box-progress:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 7em;
    white-space: nowrap;
    color: #4285f4;
    animation: loading 45s linear;
  }
  
  @keyframes loading {
    0% {
      max-width: 0;
    }
  }
  
  .btn {
    outline: none;
    padding: 0.4rem 0.8rem;
    border: 1px solid #4285f4;
    font-family: "Titillium Web", sans-serif;
    color: #4285F4;
  }
  